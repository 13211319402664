<template>
  <div class="news_container">
    <a href="/free-credits">
      <div class="news_ad">
        <div class="news_icon">
          <a href="/free-credits" title="Discounts & Free Credits">
            <img src="../assets/ads/mail.png" width="40" height="40" alt="Newsletter">
          </a>
        </div>
        <div class="news_left">
          <div class="news_title">
            Discounts & Free Credits
          </div>
          <div class="news_subtitle">
            Signup and receive discounts and free credits regularly via email!
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'CAdNewsletterLong'
}
</script>

<style lang="scss">
.news_container {
  margin-bottom: 15px;
  margin-top: 15px;
  cursor: pointer;
}

.news_icon {
  display: flex;
  padding-left: 10px;
  padding-top: 5px;
  align-items: center;
}

.news_ad {
  box-shadow: 0 0 0 1px #15b76c;
  border-radius: 12px;
  display: flex;
  justify-content: flex-end
}

.news_left {
  width: 100%
}

.news_title {
  color: #000;
  margin-top: 6px;
  margin-left: 10px
}

.news_subtitle {
  margin-left: 10px;
  color: #15b76c;
  text-transform: none;
}

.news_btn {
  background-color: transparent;
  color: #15b76c;
  padding: 1.05rem;
}

</style>
